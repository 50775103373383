<template>
  <div class="back-color">
    <div class="header-background">
    </div>
    
    <div class="dialog-content">
      <div class="line-content line-content-first-t">
        <span class="left content-title-msg">手机号码</span>
        <cube-input v-model="form.mobilePhone" class="content-input-cube" :maxlength="11" placeholder="请输入手机号" type="text"></cube-input>
      </div>

       <div class="line-content line-content-t">
        <span class="left content-title-msg">验证码</span>
        <cube-input v-model="form.vcode" class="content-input-cube content-input-code" :maxlength="6" placeholder="请输入验证码" type="text"></cube-input>
        <cube-button class="content-button-cube" @click="sendCaptcha()">
          <span v-if="sendCaptchaEnabled">发送验证码</span>
          <span v-else>{{counterTimer}}秒后获取</span>
        </cube-button>
      </div>
      <div class="line-content line-content-t">
        <span class="left content-title-msg">邀请码</span>
        <div class="disable-span">{{form.invitationCode}}</div>
        <!-- <cube-input disabled v-model="form.code" class="content-input-cube content-disabled-cube" type="text"></cube-input> -->
      </div>

      <div class="line-content line-content-t" v-if="imageCodeSrc !== ''">
        <span class="left content-title-msg">图片验证码</span>
        <cube-input v-model="form.imgVerifyCode" class="content-input-cube content-input-code" :maxlength="10" placeholder="请输入" type="text"></cube-input>
        <!-- <cube-button class="content-button-cube">
          <span>发送验证码</span>
        </cube-button> -->
        <img class="content-button-cube" style="padding-bottom: 2px;padding-right: 2px;" :src="base64Prefix+imageCodeSrc">
      </div>

      <div class="content-checkbox-cube">
        <cube-checkbox v-model="checked" shape="square">{{`  我已阅读并同意`}}</cube-checkbox>
        <span class="select-msg" @click="readClick">《大大汽修用户服务协议》</span>
      </div>

      <div class="content-next-button">
        <cube-button class="next-button" @click="nextStep"> 下一步 </cube-button>
      </div>
    </div>

    <div v-if="protocolStatue" class="protocol-class">
      <iframe src="http://qixiuh5.dada365.com/app_grand_ceremony/protocol.html" id="mobsf" scrolling="" frameborder="0" style="height:100%;width:100%;"></iframe>

      <cube-button class="protocol-close-button" @click="closeUserProtoc0l"> 关闭 </cube-button>
    </div>
    
  </div>
</template>



<script>
import { setSessionStorage } from 'utils/store'
import { getMiniAgencyCode, getMiniAgencyImgCode, checkVcode } from 'api/user'
import { isWeiXin, isIOS } from 'utils/wx'
export default {
  data () {
    return {
      protocolStatue: false,
      form: {
        mobilePhone: '',
        vcode: '',
        invitationCode: '',
        imgVerifyCode: ''
      },
      base64Prefix: 'data:image/png;base64,',
      imageCodeSrc: '',
      checked: false,
      sending: false,
      sendCaptchaEnabled: true,
      counterTimer: 60,
      counterTimer1: 60
    }
  },
  mounted () {
    let invitationCode = this.$route.query['no']
    this.form.invitationCode = invitationCode
    setSessionStorage('invitationCode', invitationCode)
    this.geKeyboardStatue()
  },
  methods: {
    geKeyboardStatue () {
      // 是否归为
      let isReset = true
      console.log('isIOS', isIOS())
      console.log('isWeiXin', isWeiXin())
      if (isIOS() && isWeiXin()) {
        document.body.addEventListener('focusin', () => {
          // 软键盘弹出的事件处理
          isReset = false
          console.log('软键盘弹出')
        })
        document.body.addEventListener('focusout', () => {
          // 软键盘收起的事件处理
          isReset = true
          console.log('软键盘收起')
          setTimeout(() => {
            // 当焦点在弹出层的输入框之间切换时先不归位
            if (isReset) {
              // 失焦后强制让页面归位
              window.scroll(0, 0)
            }
          }, 300)
        })
      }
    },
    closeUserProtoc0l () {
      this.protocolStatue = false
    },
    // 手机号验证
    getIphoneTrim () {
      if (!this.form.mobilePhone.trim()) {
        const toast = this.$createToast({
          txt: `${'请输入手机号'}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return false
      }
      if (!/^1\d{10}$/.test(this.form.mobilePhone.trim())) {
        const toast = this.$createToast({
          txt: `${'请输入有效的手机号'}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return false
      }
      return true
    },
    getTrimParams () {
      console.log('========getTrimParams==========', this.form)
      if (!this.getIphoneTrim()) return false
      if (!this.form.vcode.trim()) {
        const toast = this.$createToast({
          txt: `${'请输入验证码'}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return false
      }
      if (!/^\d{6}$/.test(this.form.vcode.trim())) {
        const toast = this.$createToast({
          txt: `${'验证码只支持6位数字'}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return false
      }
      if (this.imageCodeSrc !== '' && this.form.imgVerifyCode === '') {
        const toast = this.$createToast({
          txt: `${'请输入图形验证码'}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return false
      }
      if (!this.checked) {
        const toast = this.$createToast({
          txt: `${'请勾选用户服务协议'}`,
          type: 'warn',
          time: 2000
        })
        toast.show()
        return false
      }
      return true
    },
    nextStep () {
      if (!this.getTrimParams()) return
      if (this.sending) return
      let params = {
        phone: this.form.mobilePhone,
        vcode: this.form.vcode,
        invitationCode: this.form.invitationCode,
        imgCode: this.form.imgVerifyCode
      }
      console.log('===17621541266=====下一步按钮==params:', params)
      // // -----------------测试-----------
      // const token = '68c85167526eeacebb538c7f72982831559feb39'
      // setSessionStorage('token', token)
      // setSessionStorage('phone', '17621541266')
      // this.$router.push({
      //   name: '门店资质'
      // })
      // if (params !== '{}') return
      // ----------------测试-------------
      this.sending = true
      checkVcode(JSON.stringify(params)).then((res) => {
        console.log('========下一步按钮==res:', res)
        this.sending = false
        let { result, data, desc } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        console.log('========data==res:', data)
        // 验证手机号之后，会返回一个token
        // const token = data.data
        // const token = '68c85167526eeacebb538c7f72982831559feb39'
        setSessionStorage('token', data)
        setSessionStorage('phone', params.phone)
        this.$router.push({
          name: '门店资质'
        })
      }).catch(() => {
        this.sending = false
      })
    },
    getImageCode (phone) {
      getMiniAgencyImgCode(phone).then(res => {
        console.log('====getMiniAgencyImgCode===res:=', res)
        const data = res.data
        if (data.result === 0) {
          this.imageCodeSrc = data.desc
        }
      })
    },
    // 发送短信验证码接口
    sendCaptcha () {
      if (!this.getIphoneTrim()) return
      if (!this.sendCaptchaEnabled) return
      // 这个sending是用来控制不能重复点击的
      if (this.sending) return
      let params = {
        phone: this.form.mobilePhone,
        imgVerifyCode: this.form.imgVerifyCode
      }
      this.sending = true
      console.log('===========短信验证码params：', params)
      getMiniAgencyCode(JSON.stringify(params)).then((res) => {
        console.log('=====17621541266=====短信验证码res：', res)
        this.sending = false
        let { result, desc, data } = res.data
        if (result !== 0) {
          const toast = this.$createToast({
            txt: `${desc}`,
            type: 'error',
            time: 2000
          })
          toast.show()
          return
        }
        this.getTimeCaptchaEnable()
        const verifyCode = data.verifyCode
        if (verifyCode !== 0) { // 需要图形验证码
          this.getImageCode(params.phone)
        }
        this.sendCaptchaEnabled = false
      }).catch(() => {
        this.sending = false
      })
      // this.sendCaptchaEnabled = false
      // this.timer = setInterval(() => {
      //   if (this.counterTimer <= 0) {
      //     this.counterTimer = 60
      //     this.sendCaptchaEnabled = true
      //     clearInterval(this.timer)
      //   }
      //   this.counterTimer--
      // }, 1000)
    },
    getTimeCaptchaEnable () {
      this.sendCaptchaEnabled = false
      this.timer = setInterval(() => {
        if (this.counterTimer <= 0) {
          this.counterTimer = 60
          this.sendCaptchaEnabled = true
          clearInterval(this.timer)
        }
        this.counterTimer--
      }, 1000)
    },
    // 用户服务协议连接
    readClick () {
      this.protocolStatue = true
    }
  }
}
</script>

 <style lang="scss" scoped>
@import "~styles/index.scss";
.back-color{
  background:rgba(60,117,190,1);
  height:100vh;
}
.header-background{
  background: url(~images/garage/header.png) no-repeat;
  background-size: cover;
  height: px2rem(353px);
}
.dialog-content{
  height:px2rem(792px);
  background:rgba(255,255,255,1);
  box-shadow:px2rem(0px) px2rem(2px) px2rem(24px) px2rem(0px) rgba(0,0,0,0.12);
  border-radius:px2rem(14px);
  margin: px2rem(0px) px2rem(30px);
  padding-left: px2rem(30px);
  padding-right: px2rem(30px);
}
.line-content-t{
  margin-top: px2rem(30px);
}
.line-content-first-t{
  padding-top: px2rem(60px);
}
.line-content{
  display: flex;
  height:px2rem(94px);
  .content-title-msg{
    height:px2rem(28px);
    width: px2rem(166px);
    font-size:px2rem(28px);
    color:rgba(54,54,54,1);
    line-height: px2rem(90px);
  }
  .content-input-cube{
    height:px2rem(90px);
    width: px2rem(444px);
    padding-left: px2rem(20px);
    border-radius:px2rem(4px);
    border:px2rem(1px) solid rgba(228,228,228,1);
  }
  .disable-span{
    height:px2rem(90px);
    width: px2rem(434px);
    line-height: px2rem(90px);
    background:rgba(246,246,246,1);
    border-radius:px2rem(4px);
    border:px2rem(2px) solid rgba(228,228,228,1);
    font-size:px2rem(28px);
    font-family:PingFangSC-Regular;
    font-weight:400;
    color:rgba(153,153,153,1);
    padding-left: px2rem(20px);
  }
  .content-disabled-cube{
    color: red;
    background: #f5f7fa;
    .disable .cube-input-field{
      color: red;
    }
  }
  .content-input-code{
    width: px2rem(230px) !important;
  }
  .content-button-cube{
    width: px2rem(190px);
    height: px2rem(86px);
    background: white;
    border: px2rem(1px) solid rgba(64,129,214,1) !important;
    border-radius:inherit;
    padding-left: px2rem(25px);
    margin-left: px2rem(20px);
    font-size: px2rem(28px);
    color:rgba(64,129,214,1);
  }
}
.content-checkbox-cube{
  font-size: px2rem(28px);
  color:rgba(153,153,153,1);
  height: px2rem(40px);
  margin-top: px2rem(28px);
  .cube-checkbox{
    display: inline-block;
    padding: 0;
    .cube-checkbox-wrap{
      padding: 0;
    }
  }
  .select-msg{
    color: #4081D6;
  }
}
.content-next-button{
  margin-top: px2rem(62px);
  .next-button{
    height: px2rem(90px);
  }
}
.protocol-class{
  position:absolute;
  top:0px;
  height:100%;
  width:100%;
  background: white;
  .protocol-close-button{
    position:fixed;
    z-index:999;
    bottom: px2rem(60px);
    width: px2rem(610px);
    margin-left: px2rem(60px);
  }
}
</style>